import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { Styling } from "../styles/pages/alumni.styled";
import { SplitStyling } from "../styles/pages/default-split.styled";
import Arrow from "../images/arrow_down_right.inline.svg";
import { handleLogin, isLoggedIn, isAlumni } from "../utils/auth";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import gsap from "gsap";
import axios from "axios";
import Seo from "../components/global/seo";

export default function Alumni({ data }) {
  let rememberedUser;
  if (typeof window !== "undefined") {
    rememberedUser = window?.localStorage?.rememberUser;
  } else {
    rememberedUser = undefined;
  }

  const [username, setUsername] = useState(rememberedUser);
  const [password, setPassword] = useState();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [resetEmail, setResetEmail] = useState();
  const [instructionText, setInstructionText] = useState(
    "Please follow the steps below to set your password"
  );
  // const [isAlumni, setIsAlumni] = useState(false)
  const [validationCode, setValidationCode] = useState();
  const [resetPassword, setResetPassword] = useState();
  const [remember, setRemember] = useState(false);
  const baseUrl = "https://admin.toolroomacademy.com/wp-json/bdpwr/v1/";
  const resetPasswordUrl = baseUrl + "reset-password";
  const validateCodeUrl = baseUrl + "validate-code";
  const setNewPasswordUrl = baseUrl + "set-password";
  const [isLoggedInAlumni, setIsLoggedInAlumni] = useState(false);

  const handleSubmit = () => {
    const redirectUrl = `/course-dashboard/`;
    const checkAlumni = false;
    handleLogin({ username, password, remember, redirectUrl, checkAlumni });
  };

  function reset(resetEmail) {
    if (typeof window !== "undefined") {
      axios
        .post(resetPasswordUrl, {
          email: resetEmail,
        })
        .then((r) => {
          //console.log(r.data);
          setInstructionText(`We have now sent you an email with your validation code!`);
          setCurrentSlideIndex(1);
        })
        .catch((e) => {
          //console.log(e.response.data.message);
          setInstructionText(e.response.data.message);
          // alert('Whoops, something went wrong. Please contact cal@toolroomacademy.com for further support.')
        });
    }
  }

  function validate(validationCode) {
    if (typeof window !== "undefined") {
      axios
        .post(validateCodeUrl, {
          email: resetEmail,
          code: String(validationCode),
        })
        .then((r) => {
          //console.log(r.data);
          setInstructionText(`Great! Now please set your new password.`);
          setCurrentSlideIndex(2);
        })
        .catch((e) => {
          // //console.log(e);
          //console.log(e.response.data.message);
          setInstructionText(e.response.data.message);
          // alert('Whoops, something went wrong. Please contact cal@toolroomacademy.com for further support.')
        });
    }
  }

  function setNewPassword(validationCode, resetPassword) {
    if (typeof window !== "undefined") {
      axios
        .post(setNewPasswordUrl, {
          email: resetEmail,
          code: String(validationCode),
          password: resetPassword,
        })
        .then((r) => {
          //console.log(r.data);
          handleSubmit();
          setInstructionText("Awesome! Your password has now been set.");
          resetFormSlidesToInitialState();
        })
        .catch((e) => {
          //console.log(e.response.data.message);
          setInstructionText(e.response.data.message);

          // alert('Something went wrong.')
        });
    }
  }

  const handleUpdateResetEmail = (event) => {
    setResetEmail(event.target.value);
    setUsername(event.target.value);
  };
  const handleUpdateValidationCode = (event) => {
    setValidationCode(event.target.value);
  };
  const handleUpdateResetPassword = (event) => {
    if (event.target.value.match(/\\$/)) {
      setInstructionText("Passwords cannot contain the backslash (\\) symbol.");
    } else {
      setInstructionText("");
      setResetPassword(event.target.value);
      setPassword(event.target.value);
    }
  };

  const handleResetAndTransition = () => {
    reset(resetEmail);
  };
  const handleValidateAndTransition = () => {
    validate(validationCode);
  };
  const handlePasswordAndTransition = () => {
    setNewPassword(validationCode, resetPassword);
  };

  const resetFormSlidesToInitialState = () => {
    gsap.to(".set-password .reset-form .form-styles", { x: `0%` });
    setInstructionText(`We will now log you into your account!`);
  };

  useEffect(() => {
    gsap.to(".set-password .reset-form .form-styles", {
      x: `-${150 * currentSlideIndex}%`,
    });
  }, [currentSlideIndex]);

  return (
    <Styling>
      <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />

      <SplitStyling>
        <div className="left image-wrapper">
          <GatsbyImage
            image={getImage(data?.wpPage?.alumniFields?.alumniFormImage?.localFile)}
            className="image"
            alt={"test"}
          />
        </div>
        <div className="right set-password">
          <div className="right-inner">
            <h2 className="main_title">
              <Arrow /> Set your password
            </h2>
            <div className="form-text">
              <p
                className={
                  instructionText === "Passwords cannot contain the  symbol." ? "red" : ""
                }>
                {instructionText}
              </p>
            </div>
            <div className="forms-wrapper">
              <div className="reset-form fakeForm-b">
                <div className="form-styles">
                  <div className="form-step-1-b form-step">
                    <input
                      type="text"
                      placeholder="Email address"
                      onChange={(e) => handleUpdateResetEmail(e)}
                      className="reset-email col-1"
                    />
                    <button
                      onClick={() => handleResetAndTransition()}
                      className="send-reset-form submit_button capsule_button black col-1">
                      Next
                    </button>
                  </div>
                  <div className="form-step-2-b form-step">
                    <input
                      type="text"
                      placeholder="Validation code"
                      onChange={(e) => handleUpdateValidationCode(e)}
                      className="reset-validation col-1"
                    />
                    <button
                      onClick={() => handleValidateAndTransition()}
                      className="send-reset-form submit_button capsule_button black col-1">
                      Next
                    </button>
                  </div>
                  <div className="form-step-3-b form-step">
                    <input
                      type="password"
                      placeholder="Enter new password"
                      onChange={(e) => handleUpdateResetPassword(e)}
                      className="reset-password col-1"
                    />
                    <button
                      onClick={() => handlePasswordAndTransition()}
                      className="send-reset-form submit_button capsule_button black col-1">
                      Set password
                    </button>
                  </div>
                  <div className="form-step-4-b form-step">
                    <p>
                      Thanks your password was set. To view your courses, please login to your
                      course dashboard.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SplitStyling>
    </Styling>
  );
}

export const query = graphql`
  {
    wpPage(title: { eq: "Alumni" }) {
      id
      seo {
        metaDesc
        title
      }
      alumniFields {
        alumniText
        alumniTextBeforeLoginForm
        alumniFormImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
